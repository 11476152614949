import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import AFTableColumn from 'af-table-column';
import ExTableColumn from 'ex-table-column';
import './icons'
Vue.component(ExTableColumn.name, ExTableColumn);
Vue.use(AFTableColumn);
// import iView from 'iview';
// import 'iview/dist/styles/iview.css';
import VueI18n from 'vue-i18n';
import { messages } from './components/common/i18n';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './assets/scss/commonStyle.scss'
// import './assets/css/theme-green/index.css'; // 浅绿色主题
import './assets/css/icon.css';
import './components/common/directives';
import 'babel-polyfill';
import animated from 'animate.css' ;
import resource from 'vue-resource';
import axios from "axios";
axios.defaults.withCredentials = true;
import UmyUi from 'umy-ui';
import 'umy-ui/lib/theme-chalk/index.css';
import commonComponents from './config/commonComponents.config.js'
axios.defaults.baseURL='http://159.75.96.245:8096';
// axios.defaults.baseURL='http://192.168.2.32:8080';
Vue.prototype.$axios = axios;
Vue.use(UmyUi);
Vue.use(resource)
Vue.http.options.root='http://159.75.96.245:8096';
// Vue.http.options.root='http://192.168.2.32:8080';
Vue.use(animated)
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(commonComponents)
Vue.use(ElementUI, {
    size: 'small'
});
const i18n = new VueI18n({
    locale: 'zh',
    messages
});

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app');
