<template>
  <el-date-picker
    v-model="date"
    unlink-panels
    :value-format="$attrs['value-format'] || 'yyyy-MM-dd'"
    :type="$attrs.type || 'daterange'"
    :align="$attrs.align || 'right'"
    :range-separator="$attrs['range-separator'] || '至'"
    :start-placeholder="$attrs['start-placeholder'] || '开始日期'"
    :end-placeholder="$attrs['end-placeholder'] || '结束日期'"
    :picker-options="pickerOptions"
    :clearable="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </el-date-picker>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'updateModel'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      date: [],
      nowDateInfo: {
        year: new Date().getFullYear(),
        // 月
        month: new Date().getMonth(),
        // 日
        date: new Date().getDate(),
        // 星期
        day: new Date().getDay()
      },
      oneDay: 8.64e7,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
        shortcuts: [
          {
            text: '今日',
            onClick: (picker) => {
              picker.$emit('pick', [new Date(), new Date()]);
            }
          },
          {
            text: '昨日',
            onClick: (picker) => {
              picker.$emit('pick', [new Date(Date.now() - this.oneDay), new Date(Date.now() - this.oneDay)]);
            }
          },
          {
            text: '这个星期',
            onClick: (picker) => {
              const t = this.nowDateInfo.day;
              picker.$emit('pick', [new Date(Date.now() - this.oneDay * (t - 1)), new Date()]);
            }
          },
          {
            text: '上个星期',
            onClick: (picker) => {
              const t = this.nowDateInfo.day;
              picker.$emit('pick', [new Date(Date.now() - this.oneDay * (t + 6)), new Date(Date.now() - this.oneDay * t)]);
            }
          },
          {
            text: '这个月份',
            onClick: (picker) => {
              picker.$emit('pick', [new Date(new Date().setDate(1)), new Date()]);
            }
          },
          {
            text: '上个月份',
            onClick: (picker) => {
              const { year, month } = this.nowDateInfo;
              const lastDate = new Date(year, month, 0).getDate();
              const start = new Date(year, month - 1, 1);
              const end = new Date(year, month - 1, lastDate);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '过去7天',
            onClick: (picker) => {
              picker.$emit('pick', [new Date(Date.now() - this.oneDay * 6), new Date()]);
            }
          },
          {
            text: '过去14天',
            onClick: (picker) => {
              picker.$emit('pick', [new Date(Date.now() - this.oneDay * 13), new Date()]);
            }
          },
          {
            text: '过去30天',
            onClick: (picker) => {
              picker.$emit('pick', [new Date(Date.now() - this.oneDay * 29), new Date()]);
            }
          }
        ]
      }
    };
  },
  mounted() {
    // 初始化date
    let unwatchFn = this.$watch('value', (newVal) => {
      this.date = newVal;
      unwatchFn();
    });
  },
  methods: {
    longtime(time) {
      this.date = time;
    }
  },
  watch: {
    date(newVal) {
      if (newVal && newVal.length != 0) {
        // 更新双向绑定的值
        this.$emit('updateModel', newVal);
      }
    }
  }
};
</script>

<style lang='scss' scoped>
</style>