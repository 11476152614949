<template>
  <div class="pagination">
    <el-pagination
      :background="$attrs.background != 'undefined' ? $attrs.background : true"
      :hide-on-single-page="$attrs['hide-on-single-page'] != 'undefined' ? $attrs['hide-on-single-page'] : true"
      :layout="$attrs.layout || 'total, sizes, prev, pager, next, jumper'"
      :page-sizes="sizes"
      v-bind="$attrs"
      v-on="$listeners">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sizes: [50, 100, 150, 200],
    }
  },
}
</script>

<style lang='scss' scoped>
.pagination{
  text-align: center;
  margin-top: 20px;
}
</style>